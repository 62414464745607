import React from "react";
import Overlay from "../../componants/overlay/overlay";
import MediaQuery from "react-responsive";
import "./notFound.css";

const NotFound: React.FC = () => {
    return (
        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div
                        className="backgroundStripe"
                    >
                        <div className="maintext">
                            Page Not found
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <div className="mobileSlashError">
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default NotFound;