import React, { useState } from "react";
import Overlay from "../../componants/overlay/overlay";
import { Button } from "antd";
import { LaptopOutlined, MobileOutlined } from "@ant-design/icons";
import MediaQuery from "react-responsive";

import "./home.css";

const titleDictionary: any = {
    1: `ATRAX PLATFORM`,
    2: `ATRAX APP`
}

const wordDictionary: any = {
    1: `ATRAX was created entirely out of a need to meet operational tracking and location requirements that were missing in the market. Born out of frustration a group of Australian and UK end users came together and developed a tactical device agnostic platform based upon operational effectiveness and evidential requirements.`,
    2: `iOS and Android featuring Blue Force - Red Force Tracking, Push Notifications`
}

const secondaryDictionary: any = {
    1: ``,
    2: `The ATRAX App is free of charge and provides situational awareness tracking and location. The ATRAX App is available for iOS and Android devices.`,
}

const Home: React.FC = () => {

    const [slide, setSlide] = useState(1);
    const [text, setText] = useState(wordDictionary[1]);
    const [secondaryText, setSecondaryText] = useState(secondaryDictionary[1]);
    const [title, setTitle] = useState(titleDictionary[1]);

    const handleSlide = (slide: number) => {
        setSlide(slide);
        setText(wordDictionary[slide])
        setSecondaryText(secondaryDictionary[slide])
        setTitle(titleDictionary[slide])
    }

    return (
        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div className="backgroundSlash">
                        <img
                            className="mainTitle"
                            alt="atrax logo"
                            src="../../assets/images/ATRAX_TITLE.svg"
                        />
                        <div
                            className="flexcontainer"
                        >
                            <div
                                className="laptop"
                            >
                                <div
                                    className="laptopButtons"
                                >
                                    <Button
                                        className="buttonStyle"
                                        onClick={() => handleSlide(1)}
                                        icon={
                                            <LaptopOutlined
                                                className={slide === 1 ? "buttonSelected" : "buttonImage"}
                                                alt="live"
                                            />
                                        }
                                    />
                                    <Button
                                        className="buttonStyle"
                                        onClick={() => handleSlide(2)}
                                        icon={
                                            <MobileOutlined
                                                className={slide === 2 ? "buttonSelected" : "buttonImage"}
                                                alt="mobile"
                                            />
                                        }
                                    />
                                </div>
                                <img
                                    className="laptopFrame"
                                    alt="laptop"
                                    src={`../../assets/images/LaptopMockup${slide}.png`}
                                />
                            </div>
                            <div
                                className="mainText"
                            >
                                <p className="titleText">{title}</p>
                                <p>{text}</p>
                                <p>{secondaryText}</p>
                            </div>
                        </div>
                    </div>
                    {/* Prerendering of images */}
                    <img
                        src="../../assets/images/LaptopMockup2.png"
                        alt="laptop"
                        className="preRender"
                    />
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <div className="mobileSlashHome">
                        <img
                            className="laptopFrameMobile"
                            alt="laptop"
                            src={`../../assets/images/LaptopMockup${slide}.png`}
                        />
                        <div
                            className="laptopButtonsMobile"
                        >
                            <Button
                                className="buttonStyle"
                                onClick={() => handleSlide(1)}
                                icon={
                                    <LaptopOutlined
                                        className={slide === 1 ? "buttonSelected" : "buttonImage"}
                                        alt="live"
                                    />
                                }
                            />
                            <Button
                                className="buttonStyle"
                                onClick={() => handleSlide(2)}
                                icon={
                                    <MobileOutlined
                                        className={slide === 2 ? "buttonSelected" : "buttonImage"}
                                        alt="mobile"
                                    />
                                }
                            />
                        </div>
                        <div
                            className="mainTextMobile"
                        >
                            <p className="mobileText titleText">{title}</p>
                            <p className="mobileText">{text}</p>
                            <p className="mobileText">{secondaryText}</p>
                        </div>
                        <footer className="footerMobile">Copyright© 2023 ATRAX.</footer>
                    </div>
                </MediaQuery>
            </Overlay>
        </div >
    );
}

export default Home;