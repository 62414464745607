import React from "react";
import Overlay from "../../componants/overlay/overlay";
import MediaQuery from "react-responsive";
import "./datasheet.css";

const Datasheet: React.FC = () => {
    return (
        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div
                        className="backgroundStripe"
                    >
                        <div
                            className="headercontactimage"
                        >
                            <img
                                src="../../assets/images/REQUEST_DATA.svg"
                                alt="Contact Us"
                            />
                        </div>
                        <iframe src="https://forms.monday.com/forms/embed/49b0e7a3fd9249883981bcf976ab89e9?r=apse2"
                                width="650" height="500"
                                className="mondayForm"
                                style={{border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)"}}></iframe>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <div className="mobileSlashSheet">
                        <iframe src="https://forms.monday.com/forms/embed/49b0e7a3fd9249883981bcf976ab89e9?r=apse2"
                                width="650" height="500"
                                className="mondayFormMobile"
                                style={{border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)"}}></iframe>
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default Datasheet;