import React from "react";
import Overlay from "../../componants/overlay/overlay";
import MediaQuery from "react-responsive";

import "./aboutUs.css";

const AboutUs: React.FC = () => {
    return (
        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div
                        className="backgroundStripe"
                    >
                        <div
                            className="headerimage"
                        >
                            <img
                                src="../../assets/images/ABOUT_US.svg"
                                alt="About Us"
                            />
                        </div>
                        <div
                            className="aboutusBody"
                        >
                            <p>ATRAX is a geolocation and situational awareness platform that has been specifically developed for Law Enforcement Technical Surveillance requirements. The &#39;plug-in&#39; design approach ensures agencies can continue to realise the investment made in legacy devices and easily integrate new emerging technology.</p>
                            <p>What has been created is a platform that meets the needs of today&#39;s end users which is flexible, scalable and functional.</p>
                            <p>“ATRAX provides innovative approaches to tagging, tracking and locating operations. ATRAX solutions fuse existing technologies that geolocate and identify subjects of interest".</p>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <div
                        className="backgroundAboutStripe"
                    >
                        <div
                            className="aboutusBodyMobile"
                        >
                            <p>ATRAX is a geolocation and situational awareness platform that has been specifically developed for Law Enforcement Technical Surveillance requirements. The &#39;plug-in&#39; design approach ensures agencies can continue to realise the investment made in legacy devices and easily integrate new emerging technology.</p>
                            <p>What has been created is a platform that meets the needs of today&#39;s end users which is flexible, scalable and functional.</p>
                            <p>“ATRAX provides innovative approaches to tagging, tracking and locating operations. ATRAX solutions fuse existing technologies that geolocate and identify subjects of interest".</p>
                        </div>
                        <footer className="footerMobile">Copyright© 2023 ATRAX.</footer>
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default AboutUs;