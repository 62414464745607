import React from "react";
import MediaQuery from "react-responsive";
import Overlay from "../../componants/overlay/overlay";

import "./contactUs.css";

const ContactUs: React.FC = () => {
    return (
        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div
                        className="backgroundStripe"
                    >
                        <div
                            className="headercontactimage"
                        >
                            <img
                                src="../../assets/images/CONTACT_US.svg"
                                alt="Contact Us"
                            />
                        </div>
                        <iframe
                            className={"mondayForm"}
                        src="https://forms.monday.com/forms/embed/07fbce4753c77650dd0a1d1b4f9a7902?r=apse2"
                        width="650"
                        height="500" style={{border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)"}}></iframe>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <div
                        className="backgroundStripeContact"
                    >
                        <iframe
                           className={"mondayFormMobile"}
                            src="https://forms.monday.com/forms/embed/07fbce4753c77650dd0a1d1b4f9a7902?r=apse2"
                            width="650"
                            height="500" style={{border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)"}}></iframe>
                        <footer className="footerMobile">Copyright© 2023 ATRAX.</footer>
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default ContactUs;