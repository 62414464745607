import React, { useState } from "react";
import { Menu, Layout, Button, Drawer } from "antd";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

import type { MenuProps } from "antd";

import "./overlay.css";

const { Content, Sider } = Layout;

type MenuItems = Required<MenuProps>["items"][number];
type OverlayProps = {
    children: React.ReactNode;
}

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItems[],
): MenuItems {
    return {
        label,
        key,
        icon,
        children,
    } as MenuItems;
}

const items: MenuItems[] = [
    getItem(<Link to="/">Platform</Link>, "/", <img alt="" src="../../assets/icons/laptop.svg" />),
    getItem(<Link to="/devices">Devices</Link>, "/devices", <img alt="" src="../../assets/icons/cpu.svg" />),
    getItem(<Link to="/about">About Us</Link>, "/about", <img alt="" src="../../assets/icons/question-circle.svg" />),
    getItem(<Link to="/contact">Contact Us</Link>, "/contact", <img alt="" src="../../assets/icons/pencil-square.svg" />),
];

const Overlay: React.FC<OverlayProps> = (props: OverlayProps) => {

    const [menu, setMenu] = useState(false);

    return (
        <div>
            <MediaQuery minWidth={900}>
                <Layout className="sideLayout">
                    <Sider>
                        <Link to="/">
                            <img
                                className="logo"
                                alt="ATRAX Logo"
                                width={50}
                                src="../../assets/images/atrax_logo_inverted.png"
                            />
                        </Link>
                        <Menu
                            theme="dark"
                            mode="vertical"
                            items={items}
                            selectedKeys={[window.location.pathname]}
                        />
                    </Sider>
                    <Layout>
                        <Content
                            className="contentBackground"
                        >
                            {props.children}
                            <footer className="footer">Copyright© 2023 ATRAX.</footer>
                        </Content>
                    </Layout>
                </Layout>
            </MediaQuery>
            <MediaQuery maxWidth={901}>
                <Drawer
                    placement="left"
                    width={250}
                    open={menu}
                    onClose={() => setMenu(false)}
                >
                    <Layout>
                        <Sider>
                            <Link to="/">
                                <img
                                    className="logo"
                                    alt="ATRAX Logo"
                                    width={50}
                                    src="../../assets/images/atrax_logo_inverted.png"
                                />
                            </Link>
                            <Menu
                                theme="dark"
                                mode="vertical"
                                items={items}
                                selectedKeys={[window.location.pathname]}
                            />
                        </Sider>
                    </Layout>
                </Drawer>
                <img
                    className="hamburger"
                    alt=""
                    src="../../assets/icons/list.svg"
                    onClick={() => setMenu(true)}
                />
                {props.children}
            </MediaQuery>
        </div>
    );
}

export default Overlay;