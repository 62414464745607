import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home/home';
import AboutUs from './pages/AboutUs/aboutUs';
import Datasheet from './pages/Datasheet/datasheet';
import NotFound from './pages/NotFound/notFound';
import ContactUs from './pages/ContactUs/contactUs';
import Devices from './pages/Devices/devices';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>,
  },
  {
    path: '/about',
    element: <AboutUs/>,
  },
  {
    path: '/datasheet',
    element: <Datasheet/>,
  },
  {
    path: '/contact',
    element: <ContactUs/>
  },
  {
    path: '/devices',
    element: <Devices/>,
  },
  {
    path: '*',
    element: <NotFound/>,
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
