import { Button } from "antd";
import React from "react";
import Overlay from "../../componants/overlay/overlay";
import MediaQuery from "react-responsive";

import "./devices.css";

const Devices: React.FC = () => {
    return (
        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div
                        className="deviceBackground"
                    >
                        <div
                            className="bodyContainer"
                        >
                            <img
                                className="deviceImage"
                                src="../../assets/images/DEVICES_TEXT.svg"
                                alt="Device"
                            />
                            <div
                                className="bodytext"
                            >
                                <p>
                                    ATRAX designs and manufactures a range of tagging, tracking and locating devices specially designed for police, military and government requirements.
                                </p>
                                <p>
                                    All ATRAX hardware has been specially designed to maximise mission life. Our approach is solely focused on the end user by reducing operational risk due to our hardware’s long mission life and easy to deploy form factors.
                                </p>
                            </div>
                            <div className="datasheetText">
                                <Button
                                    className="datasheetLink"
                                    href="/datasheet"
                                    type="text"
                                >
                                    Request Datasheet
                                </Button>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <div className="mobileSlashDevices">
                        <div className="device-mobile-text">
                            <p>
                                ATRAX designs and manufactures a range of tagging, tracking and locating devices specially designed for police, military and government requirements.
                            </p>
                            <p>
                                All ATRAX hardware has been specially designed to maximise mission life. Our approach is solely focused on the end user by reducing operational risk due to our hardware’s long mission life and easy to deploy form factors.
                            </p>
                            <div className="datasheetTextMobile">
                                <Button
                                    className="datasheetLink"
                                    href="/datasheet"
                                    type="text"
                                >
                                    Request Datasheet
                                </Button>
                            </div>
                        </div>
                        <footer className="footerMobile">Copyright© 2023 ATRAX.</footer>
                    </div>
                </MediaQuery>
            </Overlay>
        </div >
    );
}

export default Devices;